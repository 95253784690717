export class SaleTicketDetail {
  saleTicketId: number;
  updateScheduleAt: string;
  saleTicketName: MultiLanguage;
  appId: string;
  appName: string;
  companyId: number;
  companyName: MultiLanguage;
  operatorId: number;
  operatorName: MultiLanguage;
  combinationTicket: Array<CombinationTicket>;
  subtitle: MultiLanguage;
  note: MultiLanguage;
  saleStartAt: string;
  saleEndAt: string;
  isSale: boolean;
  currencyUnit: string;
  priceType: string;
  priceAdult: number;
  custom1Name: MultiLanguage;
  custom1Note: MultiLanguage;
  custom2Name: MultiLanguage;
  custom2Note: MultiLanguage;
  custom3Name: MultiLanguage;
  custom3Note: MultiLanguage;
  priceCustom1: number;
  priceCustom2: number;
  priceCustom3: number;
  refundFeeAdult: number;
  refundFeeCustom1: number;
  refundFeeCustom2: number;
  refundFeeCustom3: number;
  refundFeeTicket: number;
  minCountAdult: number;
  maxCountAdult: number;
  minCountCustom1: number;
  maxCountCustom1: number;
  minCountCustom2: number;
  maxCountCustom2: number;
  minCountCustom3: number;
  maxCountCustom3: number;
  availablePeriod: number;
  availablePeriodUnit: string;
  availableDeadlineAt: string;
  refundPeriod: number;
  refundPeriodUnit: string;
  refundDeadlineAt: string;
  isDisplayInformationTicketName: boolean;
  isDisplayInformationCompanyName: boolean;
  isDisplayInformationSubtitle: boolean;
  isDisplayInformationNote: boolean;
  isDisplayInformationNote1: boolean;
  isDisplayInformationNote2: boolean;
  isDisplayInformationNote3: boolean;
  isDisplayInformationSalesName1: boolean;
  isDisplayInformationSalesName2: boolean;
  isDisplayInformationSalesName3: boolean;

  constructor(
    saleTicketId?: number,
    updateScheduleAt?: string,
    saleTicketName?: MultiLanguage,
    appId?: string,
    appName?: string,
    companyId?: number,
    companyName?: MultiLanguage,
    operatorId?: number,
    operatorName?: MultiLanguage,
    combinationTicket?: Array<CombinationTicket>,
    subtitle?: MultiLanguage,
    note?: MultiLanguage,
    saleStartAt?: string,
    saleEndAt?: string,
    isSale?: boolean,
    currencyUnit?: string,
    priceType?: string,
    priceAdult?: number,
    custom1Name?: MultiLanguage,
    custom1Note?: MultiLanguage,
    custom2Name?: MultiLanguage,
    custom2Note?: MultiLanguage,
    custom3Name?: MultiLanguage,
    custom3Note?: MultiLanguage,
    priceCustom1?: number,
    priceCustom2?: number,
    priceCustom3?: number,
    refundFeeAdult?: number,
    refundFeeCustom1?: number,
    refundFeeCustom2?: number,
    refundFeeCustom3?: number,
    refundFeeTicket?: number,
    minCountAdult?: number,
    maxCountAdult?: number,
    minCountCustom1?: number,
    maxCountCustom1?: number,
    minCountCustom2?: number,
    maxCountCustom2?: number,
    minCountCustom3?: number,
    maxCountCustom3?: number,
    availablePeriod?: number,
    availablePeriodUnit?: string,
    availableDeadlineAt?: string,
    refundPeriod?: number,
    refundPeriodUnit?: string,
    refundDeadlineAt?: string
  ) {
    (this.saleTicketId = saleTicketId),
      (this.updateScheduleAt = updateScheduleAt),
      (this.saleTicketName = saleTicketName),
      (this.appId = appId),
      (this.appName = appName),
      (this.companyId = companyId),
      (this.companyName = companyName),
      (this.operatorId = operatorId),
      (this.operatorName = operatorName),
      (this.combinationTicket = combinationTicket),
      (this.subtitle = subtitle),
      (this.note = note),
      (this.isSale = isSale),
      (this.saleStartAt = saleStartAt),
      (this.saleEndAt = saleEndAt),
      (this.currencyUnit = currencyUnit),
      (this.priceType = priceType),
      (this.custom1Note = custom1Note),
      (this.custom2Note = custom2Note),
      (this.custom3Note = custom3Note),
      (this.priceAdult = priceAdult),
      (this.custom1Name = custom1Name),
      (this.custom2Name = custom2Name),
      (this.custom3Name = custom3Name),
      (this.priceCustom1 = priceCustom1),
      (this.priceCustom2 = priceCustom2),
      (this.priceCustom3 = priceCustom3),
      (this.refundFeeAdult = refundFeeAdult),
      (this.refundFeeCustom1 = refundFeeCustom1),
      (this.refundFeeCustom2 = refundFeeCustom2),
      (this.refundFeeCustom3 = refundFeeCustom3),
      (this.refundFeeTicket = refundFeeTicket),
      (this.minCountAdult = minCountAdult),
      (this.maxCountAdult = maxCountAdult),
      (this.minCountCustom1 = minCountCustom1),
      (this.maxCountCustom1 = maxCountCustom1),
      (this.minCountCustom2 = minCountCustom2),
      (this.maxCountCustom2 = maxCountCustom2),
      (this.minCountCustom3 = minCountCustom3),
      (this.maxCountCustom3 = maxCountCustom3),
      (this.availablePeriod = availablePeriod),
      (this.availablePeriodUnit = availablePeriodUnit),
      (this.availableDeadlineAt = availableDeadlineAt),
      (this.refundPeriod = refundPeriod),
      (this.refundPeriodUnit = refundPeriodUnit),
      (this.refundDeadlineAt = refundDeadlineAt);
  }
}

export interface MultiLanguage {
  ja: string;
  en: string;
}

export class CombinationTicket {
  ticketId: number;
  ticketName?: MultiLanguage;
  ticketType?: string;
  displayPriority: number;
  constructor() {}
}
