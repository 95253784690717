import { Component, OnInit } from '@angular/core';
import { Constant, FIELD_COMPONENT, MODULE_NAME } from 'app/config/constants';
import { DataService } from 'app/service/data.service';
import { MenuActionService } from 'app/service/menu-action.service';

@Component({
  selector: 'menu-ticket-manager',
  templateUrl: './menu-ticket-manager.component.html',
  styleUrls: ['./menu-ticket-manager.component.scss']
})
export class MenuTicketManagerComponent implements OnInit {
  FIELD_COMPONENT = FIELD_COMPONENT;
  MODULE_NAME = MODULE_NAME;
  isTabSalesRecord: boolean; // Check chose tab combination
  isTabReservation: boolean;
  isTabReport: boolean;
  constructor(private dataService: DataService, private menuActionService: MenuActionService) {
    this.dataService.currentData.subscribe(data => {
      switch (data[0]) {
        case Constant.IS_TAB_SALES_RECORD: {
          this.isTabSalesRecord = <boolean>data[1];
          break;
        }
        case Constant.IS_TAB_RESERVATION: {
          this.isTabReservation = <boolean>data[1];
          break;
        }
        case Constant.IS_TAB_REPORT: {
          this.isTabReport = <boolean>data[1];
          break;
        }
      }
    });
  }

  ngOnInit(): void {}

  /**
   * execute action for export list ticket in menu
   * @param {string} moduleName current module name
   */
  exportListTicket(moduleName: string): void {
    this.menuActionService.exportTickets(moduleName);
  }

  /**
   * execute action for refund web in menu
   * @param {string} moduleName current module name
   */
  refundTickets(moduleName: string): void {
    this.menuActionService.refundTickets(moduleName);
  }

  /**
   * execute action for clear setting in menu Edit
   * @param {string} moduleName current module name
   */
  clearSetting(moduleName: string) {
    this.menuActionService.clearSetting(moduleName);
  }

  /**
   * sortAndFilter
   * @param moduleName
   */
  sortAndFilter(moduleName: string) {
    this.menuActionService.sortAndFilter(moduleName);
  }
}
